<template>
  <Details
    title="membership"
    resource="memberships"
    data-provider="$raaDataProvider"
    :base-path="basePath"
    route-id-param="membershipId"
    :title-template-render="({ deletedAt }) => `${deletedAt ? 'view' : 'edit'} membership`"
    :breadcrumb-template-render="({ deletedAt }) => deletedAt ? 'view' : 'edit'"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel entity-title="membership" :view-mode="Boolean(record.deletedAt)"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <div>
        <div v-if="detailsProps.record.deletedAt" class="flex justify-end text-sm mb-4">
          {{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}
        </div>
        <MembershipForm
          :initialValues="detailsProps.record"
          :on-submit="detailsProps.handleSubmit"
          :loading="detailsProps.loading"
          @close="detailsProps.redirectToList"
        />
      </div>
    </template>
  </Details>
</template>

<script>
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import MembershipForm from "@/views/auth/raa-devtools/memberships/MembershipForm";
  import ModalNavigation from "@/mixins/ModalNavigation";
  
  export default {
    name: "MembershipEdit",
    components: {
      MembershipForm,
      Details,
      DetailsSidePanel,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      }
    }
  }
</script>
